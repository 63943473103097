import { collection, addDoc, updateDoc, getDoc, setDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db, app } from "../config.js";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { createEvent } from "./learn";




const checkAuth = (auth, user, setUser, toggle, setIsLoading) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const ios = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  auth.onAuthStateChanged(async (_user) => {
    console.log('onAuthStateChanged !!', _user);

    if (_user) {
      try {
        // Référence au document utilisateur basé sur l'UID

        const usersRef = collection(db, "users");
        const _query = query(usersRef, where("email", "==", _user.email));
        const users = (await getDocs(_query)).docs.map(doc => ({...doc.data() }))
        const userRef = doc(db, "users", users?.[0]?.id);
        console.log('_user', _user)
        console.log('users', users)
        console.log('_user uid', _user.uid)

        if (!users.length && users?.[0])  {
          console.log('utilisateur n existe pas', _user)
          
        } else {
          // Utilisateur existant, mettre à jour les informations si nécessaire
          const existingUser = users?.[0]
          setUser({ ...existingUser, id: existingUser.id });
          setIsLoading(false);

          let isPwa = null;
          if (window.matchMedia('(display-mode: standalone)').matches) {
            isPwa = true;
            console.log("L'application est installée sur l'écran d'accueil");
          }

          // Mettre à jour les informations de connexion
          await updateDoc(userRef, {
            last_connexion: new Date(),
            isPwa,
            ios,
          });
        }

        toggle();

      } catch (error) {
        console.error("Erreur lors de la vérification de l'authentification :", error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  });
};

  export {checkAuth}