import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, app } from "./../../config.js";
import {Howl, Howler} from 'howler';

const textLvl = ["En cours d'apprentissage", "Mémoire courte", "Bon souvenir", "Très bon souvenir", "Excellent souvenir", "Souvenir impérissable"]


function capitalizeFirstLetter(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}


const textSize = (txt) => {
  const splitedSentence = txt?.split("");
  return splitedSentence?.length > 11 ? "text-md"  : splitedSentence?.length > 8 ? "text-lg" : splitedSentence?.length > 5 ? "text-xl" : "text-2xl"
}

const textSizeXl = (txt) => {
  const nbc = txt?.split("");
  return nbc?.length > 80 ? "text-md"  : nbc?.length > 60 ? "text-lg" : nbc?.length > 20 ? "text-xl" : "text-2xl"
}

// const addBracket = (txt, term) => txt.replace(new RegExp(term, 'gi'), `[*$&*]`) 

function reduceInput(input, x, n) {
    if (!input ) return
    // Trouver l'index des crochets
    const startIndex = input.indexOf('[');
    const endIndex = input.indexOf(']');
    
    // Extraire la portion entre crochets sans les astérisques initiaux et finaux
    const beforeBracket = input.slice(0, startIndex);
    const insideBracket = input.slice(startIndex + 2, endIndex - 1);
    const afterBracket = input.slice(endIndex + 1);
  
    // Vérifier si x et n ne sont pas plus grands que la longueur de la portion entre crochets
    if (x + n > insideBracket.length) {
      throw new Error("x et n sont trop grands pour la portion de texte entre crochets.");
    }
  
    // Extraire les lettres du début et de la fin
    const prefix = insideBracket.slice(0, x);
    const suffix = insideBracket.slice(insideBracket.length - n);
  
    // Construire la nouvelle portion entre crochets
    const newInsideBracket = insideBracket.slice(x, insideBracket.length - n);
    const newSegment = `${prefix}[*${newInsideBracket}*]${suffix}`;
  
    // Reconstituer la chaîne finale
    const result = beforeBracket + newSegment + afterBracket;
  
    return result;
  }

const addBracket = (txt, term) => {
  // Vérifie si le terme est déjà entre crochets qui peuvent contenir d'autres termes
  const bracketedTermRegex = new RegExp(`\\[.*?${term}.*?\\]`, 'gi');
  if (bracketedTermRegex.test(txt)) {
    return txt.replace(new RegExp(bracketedTermRegex, 'gi'), `[*$&*]`).replaceAll('[*[', '[*').replaceAll(']*]', '*]')
} 

const containsBrackets = /\[.*?\]/.test(txt);
if (containsBrackets) {
  return txt.replaceAll('[', '[*').replaceAll(']', '*]')
}


  // Remplace le terme par le terme entre crochets
  return txt?.replace(new RegExp(term, 'gi'), `[*$&*]`);
};

function addBracketsToString(text, cards) {
  try {


      const wordsInflexions = cards?.map(card => card.inflexions).flat();
      const words = cards?.map(card => card.term).concat(wordsInflexions)
      const list = []

      words?.forEach(function(word) {
          // Créer une expression régulière sans lookbehind ou lookahead
          let regex = new RegExp(`\\b${word}\\b`, 'gi');
          // Remplacer manuellement
          text = text?.replace(regex, function(match, offset, fullText) {
              // Vérifier les caractères avant et après pour s'assurer qu'ils ne sont pas des crochets
              if (
                  (offset === 0 || fullText[offset - 1] !== '[') &&
                  (offset + match.length >= fullText.length || fullText[offset + match.length] !== ']')
              ) {
                  return `[${match}]`;
              }
              return match;
          });
      });
  } catch (e) {
      console.log('erreur', e);
  }
  return text;
}



// const replaceBracketsWithInput = (text, reactComponant) => {
//    const regex = /\[([^\]]+)\]/g;
//    console.log('text match', text)
//    const length = text?.match(regex) ? text?.match(regex)[0]?.length - 4 : 0

//    //`<input id="anwser"  class="px-1 bg-purple-50 rounded" placeholder="${"_".repeat(length)}" style="width: ${15*length}px"/>`

//    text = text?.replace(regex, reactComponant)
//    return text 
// }

const soundValid = new Audio('/assets/valid_sound.mp3');
// const badSound = new Audio('/assets/bad_sound.mp3');
const soundBad = new Howl({src: [`/assets/bad_sound.mp3`],html5: true, volume: 0.1});
// const newSound = new Audio('/assets/valid_sound.mp3');


const findWord = (cards, w) => {
  const transformedW = clean(w);
  return cards.find(c => 
    clean(c.term) === transformedW || c.inflexions?.some(word => clean(word) === transformedW)
  );
};

const getLemma = async(term, lang, ct) => {

  const text_request = `Donne uniquemnent la réponse.  
  Pour le terme suivant "${term}" en ${lang} donne son lemma si possible (si c'est un verbe donne l'infinif, un nom sa forme simple..) sans changer son type (nom, verbe, adverbe ..) si c'est déjà le lemma envoie le terme. 
  Si "${term}" n'est pas un mot ${lang} ou est un nom propre réponds "..."`

  const request = [{ "role": "user", "content": text_request}]
 console.log('text_request', text_request)
  const response = await gpt(request, true, ct)
  console.log('response', response)
  const data_content = response?.choices[0]?.message?.content;
  console.log('data_content', data_content);

  return data_content
  
}


const clean = (txt) => txt?.replace(/[.,!?;¡::/¿()“"'\n\r]/g, "").toLowerCase();




const extractText = (text) => (text.match(/\[\*(.*?)\*\]/g) || []).map(e => e.replace(/\[\*(.*?)\*\]/, "$1"));

const replaceBracketsWithInput = (text, InputComponent, letters, hardMode = false, contextForInput) => {
  const regex = /\[([^\]]+)\]/g;
  let match;
  let lastIndex = 0;
  const result = [];

  while ((match = regex.exec(text)) !== null) {
    const matchedText = match[0];
    const start = match.index;
    const end = start + matchedText.length;

    if (start > lastIndex) {
      result.push(text.slice(lastIndex, start));
    }

    result.push(<InputComponent contextForInput={contextForInput} hardMode={hardMode} text={extractText(matchedText)[0]} letters={letters} />);

    lastIndex = end;
  }

  if (lastIndex < text.length) {
    result.push(text.slice(lastIndex));
  }

  return result;
}

const replaceBracketsWithUnderLine = (text, style = "text-green-500") => {
  const regex = /\[([^\]]+)\]/g;


  text = text?.replace(regex, (match, capturedText) => {
    return `<div class="underline inline-block rounded underline-offset-4	decoration-dashed
    ${style}">${capturedText.replace(/\*/g, "")}</div>`;
  });

  return text;
};


function removeTextInParentheses(text) {
  const regex = /\([^()]*\)/g; // Utilisation d'une expression régulière pour trouver le texte entre parenthèses
  const result = text.replace(regex, ''); // Supprime le texte entre parenthèses de la phrase

  return result.trim(); // Retourne le texte sans le contenu entre parenthèses, en supprimant les espaces supplémentaires
}


function getTextInParentheses(text) {
  const regex = /\((.*?)\)/; // Utilisation d'une expression régulière pour trouver le texte entre parenthèses
  const matches = regex.exec(text); // Recherche des correspondances dans le texte

  if (matches && matches.length > 1) {
    return matches[1]; // Renvoie le texte trouvé entre parenthèses
  } else {
    return null; // Aucun texte entre parenthèses trouvé
  }
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // échange des éléments
  }
  return array;
}


const imageGenerator = async (data) => {
  try {
    console.log('image launched !!', data);
    const apiKey = process.env.REACT_APP_GPT_KEY_2MINDSEEDGMAIL;
    
    const response = await fetch('https://api.openai.com/v1/images/generations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      throw new Error(`Réponse de l'API OpenAI non valide : ${response}`);
    }
    
    const responseData = await response.json();
    console.log('Réponse de l\'API OpenAI :', responseData);
    responseData.data.forEach((image, index) => {
      console.log(image.url);
    });
    
    return responseData; // La fonction renvoie une promesse résolue avec responseData
  } catch (error) {
    console.error('Erreur lors de la requête vers l\'API OpenAI :', error);
    throw error; // La fonction renvoie une promesse rejetée avec l'erreur
  }
};



const tts = async (text) => {
  // Supposons que vous ayez un endpoint de serveur pour gérer la demande d'API à OpenAI.
  const response = await fetch('https://api.openai.com/v1/audio/speech', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_GPT_KEY_2MINDSEEDGMAIL}`
    },
    body: JSON.stringify({
      model: "tts-1",
      voice: "alloy",
      input: text,
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const audioBlob = await response.blob(); // Obtenez le blob audio de la réponse
  const audioUrl = URL.createObjectURL(audioBlob); // Créez un URL pour le blob

  // Créez un nouvel élément audio et jouez-le
  const audio = new Audio(audioUrl);
  audio.play();
}

const gpt = async (prompt, chat = false, ct, opt) => {
  console.log('GTP 🤑🤑🤑🟠🟠🟠🟠🟠🟠🟠🟠🟠🟠🟠🟠🟠', chat)
 
  const apiKey = process.env.REACT_APP_GPT_KEY_2MINDSEEDGMAIL
  const url = chat ? 'https://api.openai.com/v1/chat/completions' : "https://api.openai.com/v1/completions";
  
  

  let requestData = chat ? {
    model: opt?.model || 'gpt-4o-mini',
    messages: prompt,
    top_p: 0.9,
    temperature: opt?.temp || 0.9
  } : {
    model: opt?.model || 'gpt-3.5-turbo-0125',
    messages: prompt,
    top_p: 0.9,
    temperature: opt?.temp || 0.9
  }

  if (opt?.model == "o1-preview" || opt?.model == "o1-mini"  ) {
    requestData = {
      model: opt?.model,
      messages: prompt}

    }



  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    },
    body: JSON.stringify(requestData)
  };


  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    console.log('data', data)
 
    const usage = data.usage

    if (ct){
    if (data.usage && ct.userWorkspace){
      const newUW = {...ct.userWorkspace, 
        completion_tokens: usage?.completion_tokens + ct.userWorkspace.completion_tokens || 0, 
        prompt_tokens: usage?.prompt_tokens + ct.userWorkspace.prompt_tokens || 0,
        total_tokens: usage?.total_tokens + ct.userWorkspace.total_tokens || 0
      }
      
     
        ct.setUserWorkspace(newUW)
        ct.fire.updateUserWorkspace(newUW)
        ct.fire.updateDaily({...ct.userDaily, total_tokens: (ct.total_tokens||0) +usage?.total_tokens}, ct)
        
    
    }
    }
      
    return data
  } catch (error) {
    console.error(error);
  }
};







function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  if (seconds < 120) {
    return seconds + "s" 
  }
  if (seconds < 60 * 60) {
    return minutes + "min" 
  }
  return `${hoursStr}h${minutesStr}`;
}


function getTitle(chaine) {
    let regExp = /\(.*?\)/; // Expression régulière pour matcher la première paire de parenthèses
    let match = regExp.exec(chaine);
    if (match) {
      return extractTextWithTags(chaine.substring(0, match.index).trim());
    } else {
      return extractTextWithTags(chaine);
    }
  }


function getCons(chaine) {
    let regExp = /\(([^)]+)\)/; // Expression régulière pour matcher le contenu entre parenthèses
    let match = regExp.exec(chaine);
    if (match) {
      return extractTextWithTags(match[1]);
    } else {
      return "";
    }
  }

  const highlightedWords = (texte, knowWords, allWords) => {
    const regex = new RegExp(`(\\s|^)(${allWords?.join("|")})(\\s|$)`, "gi");
    texte = texte?.replace(regex, (match, before, word, after) => {
      let replacedWord;
      if (knowWords.includes(word.toLowerCase())) {
        replacedWord = `<i class="mx-1">${word}</i>`;
      } else {
        replacedWord = `<span class="mx-1">${word}</span>`;
      }
      if (word == "" || word == " ") return ""
      return before + replacedWord + after;
    });
    return <div className="text-content" style={{display: "content"}} dangerouslySetInnerHTML={{ __html: texte }} />;
  }

  // const highlightedWords = (texte, knowWords, allWords) => {
  //   console.log('texte', texte)
  //   const regex = new RegExp(`(?<=\\s|^)(${allWords?.join("|")})(?=\\s|$)`, "gi");
  //   texte = texte?.replace(regex, (match) => {
  //     if (knowWords.includes(match.toLowerCase())) {
  //       return `<i>${match}</i>`;
  //     } else {
  //       return `<span class="ml-[2px] mr-[1px]">${match}</span>`;
  //     }
  //   });
    
  //   // Restaurer les caractères spéciaux
  //   texte = texte.replace(/ \b(\w) /g, "$1");
  
  //   return <div className="text-content" style={{display: "content"}} dangerouslySetInnerHTML={{ __html: texte }} />;
  // }

const clearBrackets = text => text.replace("[", '').replace(']', '')

function makeBold(string, cards, action, words, allwords, trigger_index) {

    const regex = /\[([^\]]+)\]/g;
    const parts = string?.split(regex);


   
    return parts?.map((part, index) => {

   
      if (index % 2 === 1) {

          const card = cards?.find(c => extractTextWithTags(c.term).toLowerCase() == part.toLowerCase() || c.inflexions.map(w => w.toLowerCase()).includes(part.toLowerCase()))
  
         
          const newDate2 = new Date();
         newDate2.setSeconds(newDate2.getSeconds() + 40);

          let decoration = "underline decoration-dashed decoration-[2px] underline-offset-[5px]"
          let color = card?.user_card?.next_date > new Date() ? 'decoration-green-400' : " decoration-purple-400 text-purple-500"
          if (!card) color = "decoration-pink-500"
          if (card && !card.user_card.next_date) color = "decoration-yellow-400  "
          // if (card.state == "mastered") decoration =""


          const neutral = <span className={`decoration-slate-500 underline decoration-dashed decoration-[1px] underline-offset-[5px] mx-1 !text-slate-600  transition hover:scale-110 cursor-pointer pulse`}key={index}>{part}</span>
          const _new = <span onClick={() => { action([card])}} className="border-yellow-400  mx-1 rounded-md border-[2px] bg-yellow-50 px-1 animate-bounce-light border-dashed decoration-dashed decoration-[2px] underline-offset-[5px] !text-amber-600 inline-block" key={index}>{part}</span>
          const comp = <span onClick={() => {console.log('part', part);action([card])}} className="border-blue-400 rounded-md border-[2px] bg-blue-50 px-1 mx-1 animate-bounce-light border-dashed decoration-dashed decoration-[2px] underline-offset-[5px] !text-blue-600 inline-block" key={index}>{part}</span>
          const compOral = <span onClick={() => {console.log('part', part);action([card])}} className="border-blue-400 rounded-full border-[2px] bg-blue-50 px-1 mx-1 animate-bounce-light border-dashed decoration-dashed decoration-[2px] p-1 underline-offset-[5px] !text-blue-600 inline-block flex items-center" key={index}>
       

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-[2px] rounded-full  border-blue-500/50 h-4">

  <path d="M6.3 2.84A1.5 1.5 0 0 0 4 4.11v11.78a1.5 1.5 0 0 0 2.3 1.27l9.344-5.891a1.5 1.5 0 0 0 0-2.538L6.3 2.841Z" />
        </svg>
        <div className="h-[4px] w-[28px] mr-1 bg-blue-500/50 rounded-full"></div>
        </span>

          const exp =  <span onClick={() => {console.log('part', part);action([card])}} className="border-purple-400 fredoka rounded-md text-xs mx-1 px-[2px]  animate-bounce-light border-[2px]  underline-offset-[5px] inline-block border-dashed decoration-dashed decoration-[2px] underline-offset-[5px] !text-purple-600/50" key={index}>{"_".repeat(part?.split("").length)}</span>

          if (trigger_index) {
            if (trigger_index == 42) {
              return !card?.user_card.next_date ? _new : neutral
            }
            if (card?.user_card?.triggers[trigger_index]?.next_date < new Date()) {
              console.log('type', trigger_index == 0 ? "comp" : "exp")
              
              return trigger_index == 0 ? (card?.user_card%2==0 ? comp : compOral) : exp
            } 
            else {
              console.log('type', 'neutral')
              return neutral

            }
          }
         
          if(!card?.user_card.next_date) {
            return _new 
          }
          if(card?.user_card?.triggers?.find(t => t.name == "Compréhension ")?.next_date  < new Date()) {
            return (card?.user_card%2==0 ? comp : compOral)
          }

          if(card?.user_card?.triggers?.find(t => t.name == "Expression ")?.next_date  < new Date()) {
            return exp
          }

        // if(card?.indexTrigger == 1 && card?.user_card?.next_date < new Date()) return <input className="border-purple-400 mx-1 border-b border-dashed decoration-dashed decoration-[2px] underline-offset-[5px] inline-block  w-[50px]"/> 


        // Le contenu entre crochets
        return neutral
      } else {
        // Le contenu en dehors des crochets
         return highlightedWords(part, words, allwords);
      }
    });
  }

  function extractTextWithTags(input) {
    const temp = document.createElement("div");
    temp.innerHTML = input;
    return temp.innerText;
  }

  function textToSpeech(text, lang) {
    const synthesis = window.speechSynthesis;
  
    // Récupère toutes les voix disponibles sur l'appareil
    const voices = synthesis.getVoices();
  
    // Trouve la voix correspondant à la langue spécifiée
    const voice = voices.find(voice => voice.lang === lang);

  
    // Si la voix est trouvée, crée un nouvel objet de synthèse vocale et le configure
    if (voice) {
      const utterance = new SpeechSynthesisUtterance(extractTextWithTags(text));
      utterance.voice = voice;
      synthesis.speak(utterance);
    } else {
      console.log(`Voix pour la langue ${lang} non trouvée.`);
    }
  }


  function highlightTextWithGoogleTTS(text) {
    const words = text?.split(" ");
    let index = 0;
    const intervalId = setInterval(() => {
      if (index >= words.length) {
        clearInterval(intervalId);
        return;
      }
      const word = words[index];
      const highlightedWord = `<span style="color: blue">${word}</span>`;
      words[index] = highlightedWord;
      const highlightedText = words.join(" ");
    //   console.log(highlightedText);
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=YOUR_API_KEY`;
      const data = JSON.stringify({
        input: { text: word },
        voice: { languageCode: "en-US", ssmlGender: "NEUTRAL" },
        audioConfig: { audioEncoding: "MP3" },
      });
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      })
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], { type: "audio/mpeg" });
          const audioUrl = URL.createObjectURL(blob);
          const audio = new Audio(audioUrl);
          audio.play();
        });
      index++;
    }, 2000);
  }





  const voices = {
    'es-ES': {name: 'en-GB-Neural2-B'}
  }

  const getLangVoice = (obj) => {
    let voice = {
      languageCode: obj.lang,
     
    }
    if (obj.lang == "en-GB") {
      voice = obj.variation == "A" ? {
        languageCode: 'en-GB',
        name: 'en-GB-Neural2-F',

      } : {
        languageCode: 'en-GB',
        name: 'en-GB-Neural2-B',
  
      }
    } else if (obj.lang == "fr-FR") {
      voice = {
        languageCode: 'fr-FR',
        name: 'fr-FR-Neural2-B',

      }
    } else if (obj.lang == "de-DE") {
      voice = obj.variation == "A" ? { languageCode: 'de-DE' ,name: 'de-DE-Neural2-B'} : { languageCode: 'de-DE', name: 'de-DE-Neural2-C'}
    }
    else if (obj.lang == "pt-BR") {
      voice = obj.variation == "A" ? {languageCode: obj.lang, name: 'pt-BR-Neural2-C'} : {languageCode: obj.lang, name: 'pt-BR-Neural2-B'}
    }
    else if (obj.lang == "es-ES") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: 'es-ES-Neural2-E'}: {languageCode: obj.lang, name: 'es-ES-Polyglot-1'}  
    }
    else if (obj.lang == "it-IT") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: obj.lang+'-Neural2-A'}: {languageCode: obj.lang, name: obj.lang+'-Neural2-C'}  
    }
    else if (obj.lang == "nl-NL") {
      voice = obj.variation == "A" ?  {languageCode: obj.lang, name: obj.lang+'-Wavenet-E'}: {languageCode: obj.lang, name: obj.lang+'-Wavenet-C'}  
    }
    else if (obj.lang == "cmN-CN" || obj.lang == "cmn-CN") {
      voice = obj.variation == "A" ?  {languageCode:'cmn-CN', name:'cmn-CN'+'-Wavenet-B'}: {languageCode:'cmn-CN', name:'cmn-CN'+'-Wavenet-B'}  
    }
    
    return voice

  }
  async function reduceAudioFileSize(audioBlob) {
    // Cet exemple suppose l'existence d'une fonction hypothétique `convertAudio`
    // qui pourrait prendre un Blob audio et des options pour le convertir.
    // Dans la pratique, cette fonctionnalité nécessiterait une bibliothèque JS complexe
    // ou un traitement côté serveur.
    const options = {
      format: 'audio/webm', // Utiliser un format compressé comme WebM
      codec: 'opus',        // Utiliser le codec Opus pour une compression efficace
      bitrate: 16000,       // Définir un bitrate plus faible pour réduire la taille
      sampleRate: 24000,    // Optionnel : réduire la fréquence d'échantillonnage pour économiser de l'espace
    };
    
    //const reducedAudioBlob = await convertAudio(audioBlob, options);
   // return reducedAudioBlob;
  }

  async function checkFileExists(storageRef) {
    try {
      // Tentative d'obtenir l'URL de téléchargement du fichier
      const downloadURL = await getDownloadURL(storageRef);
      console.log('Le fichier existe déjà :', downloadURL);
      return downloadURL; // Le fichier existe
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        console.log('Le fichier n\'existe pas.');
      } else {
        // Gérer les autres types d'erreurs éventuels
        console.error('Erreur lors de la vérification de l\'existence du fichier :', error);
      }
      return false; // Le fichier n'existe pas ou une autre erreur s'est produite
    }
  }

  const storage = getStorage(app);

  const uploadAudio = async (data, obj, storageRef) => {
    const audioBase64 = `data:audio/mp3;base64,${data.audioContent}`;
   
   
    console.log('speak obj', obj);
    console.log('speak storage', storage);


    const audioBlob = await (await fetch(audioBase64)).blob();
    await uploadBytes(storageRef, audioBlob);

    const url = await getDownloadURL(storageRef)
    const sound = new Audio(url);
  }


  let currentAudio;

  const stopSound = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
  }

  async function speak(obj, ct) {

    console.log('speak', obj);
    return new Promise(async (resolve, reject) => {
        const timeoutDuration = 15000; // 10 secondes pour le timeout
        const timeoutPromise = new Promise((_, timeoutReject) => {
            setTimeout(() => {
                timeoutReject(new Error("Le délai d'attente pour la parole a été dépassé."));
                resolve(true);
            }, timeoutDuration);
        });

        try {

            if (obj.mp3){

              console.log('mp3 !!!!!!!!!', obj.mp3);
              const sound = new Howl({
                src: [obj.mp3],
                html5: true,
                onplayerror: (e) => {
                  console.log('error onplayerror', e);
                    reject(new Error("Erreur lors du chargement du fichier audio. onplayerror"))
                  },
          
                onloaderror: (e) => {
                    console.log('error onloaderror', e);
                    reject(new Error("Erreur lors du chargement du fichier audio."));
                },
                onend: function() {
                    resolve(true);
                    console.log('Finished!');
                }
            });

            currentAudio = sound;
            sound.play();
            console.log('lecture depuis le mp3');
            return;
            }
            console.log('speak3');
            const voice = getLangVoice(obj);
            console.log('voice', voice)
            const apiKey = "AIzaSyA98BOQxv5Sp4NL53Mn_wBzWazgepDh7BQ";
            const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

            const storageRef = ref(storage, `google_speak/${obj.lang}/${encodeURIComponent(obj.text.toLowerCase())}.mp3`);

            let exist = false
            if (obj.upload) {exist = await checkFileExists(storageRef);}

            if (exist) {
                console.log('lecture depuis le storage0');

                if (currentAudio) {
                    currentAudio.stop(); // Utilisez stop() au lieu de pause() pour Howler
                }

                const sound = new Howl({
                    src: [exist],
                    html5: true,
                    onplayerror: (e) => {
                      console.log('error onplayerror', e);
                        reject(new Error("Erreur lors du chargement du fichier audio. onplayerror"))
                      },
              
                    onloaderror: (e) => {
                        console.log('error onloaderror', e);
                        reject(new Error("Erreur lors du chargement du fichier audio."));
                    },
                    onend: function() {
                        resolve(true);
                        console.log('Finished!');
                    }
                });

                currentAudio = sound;
                sound.play();
                console.log('lecture depuis le storage');
                return;
            }

            const fetchPromise = fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    input: { text: obj.text?.replace(/[\[\]]/g, '')},
                   
                    voice,
                    audioConfig: {
                        audioEncoding: 'MP3',
                        pitch: obj.variation !== "A" ? -4 : 0,
                        speakingRate: obj.speakingRate || 1,
                        sampleRateHertz: 24000 // Essa
                    }
                })
            }).then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                const sound = new Howl({
                    src: [`data:audio/mp3;base64,${data.audioContent}`],
                    html5: true,
                    onend: function() {
                        resolve(true);
                        console.log('Finished!');
                    }
                });

                if (currentAudio) {
                    currentAudio.stop(); // Assurez-vous d'arrêter tout audio en cours avant d'en jouer un nouveau
                }

                currentAudio = sound;
            
              
                sound.play();
                
                if (obj.upload) { uploadAudio(data, obj, storageRef); }
                return {test: "test"}
            }).catch(error => {
                console.log("Une erreur s'est produite lors de l'appel Google TTS :", error.message);
                ct && ct.setAlerts([{title: "Erreur lecture du son: "+JSON.stringify(error), time: 2000 }]);
                textToSpeech(obj.text, obj.lang);
                resolve(true); // Vous pourriez vouloir rejeter ici pour indiquer une erreur
            });

            // Utilisez Promise.race pour gérer le timeout
            await Promise.race([fetchPromise, timeoutPromise]);
        } catch (error) {
            console.log("Une erreur s'est produite lors de la lecture :", error.message);
            reject(error); // Assurez-vous de rejeter la promesse principale en cas d'erreur
        }
    });
}

  // async function speak(obj, ct) {
  //   console.log('speak', obj)
  //   return new Promise(async (resolve, reject) => {
  //     console.log('speak2')
  //     try {
  //       console.log('speak3')
  //     const voice = getLangVoice(obj);
  //     const apiKey = "AIzaSyA98BOQxv5Sp4NL53Mn_wBzWazgepDh7BQ";
  //     const url = `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${apiKey}`;

  //     const storageRef = ref(storage, `google_speak/${obj.lang}/${encodeURIComponent(obj.text.toLowerCase())}.mp3`);
  //     console.log('speak4')
  //     const exist = await checkFileExists(storageRef)

  //     if (exist) {
  //       console.log('lecture depuis le storage0')
       
  //       //const audio = new Audio(exist)
  //       console.log('lecture depuis le storage2')
  //       if (currentAudio) {
  //         currentAudio?.pause();
  //         currentAudio.currentTime = 0;
  //       }
  //       console.log('lecture depuis le storage3')
  //     //  currentAudio = audio;
  //       const sound = new Howl({
  //         src: [exist],
  //         html5: true,
  //         onplayerror: (e) => {
  //           ct && ct.setAlerts([{title: "Erreur lecture du son: "+JSON.stringify(e), time: 2000 }])
  //           throw new Error(`Réponse de l'API OpenAI non valide : ${JSON.stringify(e)}`);
     
  //         },
  //         onloaderror: (e) => {
  //           console.log('error loading',e)
  //           ct && ct.setAlerts([{title: "Erreur lecture du son: "+JSON.stringify(e), time: 2000 }])
  //           throw new Error(`Réponse de l'API OpenAI non valide : ${JSON.stringify(e)}`);
  //         },
  //         onend: function() {
  //           resolve(true);
  //           console.log('Finished!');
  //         }
  //       });
  //       console.log('lecture depuis le storage4')
  //       currentAudio = sound
  //       console.log('lecture depuis le storage5')
  //       sound.play()
  //       console.log('lecture depuis le storage5')
  //      // audio.play()
  //       // audio.addEventListener('ended', () => {
  //       //   resolve(true);
          
  //       // });
  //       return 
  //     }
  //     console.log('play google speak')
  
     
  //       const response = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           input: { text: obj.text },
  //           voice,
  //           audioConfig: {
  //             audioEncoding: 'MP3',
  //             pitch: obj.variation !== "A" ? -4 : 0,
  //           }
  //         })
  //       });
  
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  
  //       const data = await response.json();
  //      // const audio = new Audio(`data:audio/mp3;base64,${data.audioContent}`);
  //       const sound = new Howl({
  //         src: [`data:audio/mp3;base64,${data.audioContent}`],
  //         html5: true,
  //         onend: function() {
  //           resolve(true);
  //           console.log('Finished!');
  //         }
  //       });
  //       currentAudio = sound;
  //       sound.play()
  //       let soundPlayed = false; // Flag pour suivre si le son a commencé
  
  //       // Démarre un timer qui rejette la promesse si le son ne joue pas en 1 seconde
  //       // const timeoutId = setTimeout(() => {
  //       //   if (!soundPlayed) {
  //       //     console.log("Le son Google TTS n'a pas commencé dans le temps imparti.");
  //       //     textToSpeech(obj.text, obj.lang); // Appel de votre fonction de fallback
  //       //     resolve(true); // Résolution de la promesse pour éviter de bloquer si utilisé dans un flux asynchrone
  //       //   }
  //       // }, 1000); // Temps imparti avant de déclencher le fallback
  
  //       // audio.play().then(() => {
  //       //   soundPlayed = true; // Marque le son comme joué
  //       //   clearTimeout(timeoutId); // Annule le timeout si le son commence à jouer
  //       //   audio.addEventListener('ended', () => {
  //       //     resolve(true);
  //       //   });
  //       // }).catch(error => {
  //       //   console.log("Erreur lors de la lecture :", error);
  //       //   clearTimeout(timeoutId);
  //       //   textToSpeech(obj.text, obj.lang);
  //       //   resolve(true);
  //       // });
  
  //       if (obj.upload) { uploadAudio(data, obj, storageRef); }
  //     } catch (error) {
  //       console.log("Une erreur s'est produite lors de l'appel Google TTS :", error.message);
  //       ct && ct.setAlerts([{title: "Erreur lecture du son: "+JSON.stringify(error), time: 2000 }])
  //       textToSpeech(obj.text, obj.lang);
  //       resolve(true);
  //     }
  //   });
  
  // }



  function transformText(text) {
    // Convertir en minuscules
    let lowercaseText = text?.toLowerCase();
  
    // Enlever toute ponctuation
    let cleanedText = lowercaseText?.replace(/[.,!?;¡::/¿()“"'\n\r]/g, "").replaceAll('a:', '').replaceAll('b:', '')
  
    return cleanedText;
  }

  const removeDigits = (inputString) => inputString.replace(/\d+|\n|\r/g, '')
  const removeNoDigits = (inputString) => inputString.replace(/[^\d\n\r]/g, '');
  async function fetch50k(ct) {
    let lang = ct.workspace?.lang?.split('-')?.[0]
  

    try {
      console.log('fetching 50k 🐸🐸🐸🐸🐸🐸🐸🐸🐸🐸🐸')
        const response = await fetch(`https://raw.githubusercontent.com/hermitdave/FrequencyWords/master/content/2018/${lang}/${lang}_50k.txt`);
        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        const data = await response.text();
        ct.setText_50k(removeDigits(data).split(' '))
        ct.setInt_50k(removeNoDigits(data).split('\n'))

        return {
          text_50k: removeDigits(data).split(' '),
          int_50k: removeNoDigits(data).split('\n')
        }
        // console.log(removeDigits(data));
    } catch (error) {
        console.error('There was a problem with the fetch operation: ', error);
    }
}

  const sentenceEndRegex =/[^.!?\s][^.!?]*(?:[.!?](?!['"]?\s|$)[^.!?]*)*[.!?]?['"]?(?=\s|$)(?!\s*(?:Dr|Mrs|Mr|Ms|Jr|Sr|Prof|Rev|Gen|Rep|Sen)\.*(?:\s|$))/g;


  const splitNumeredSentence = (text) => {
    if (!text) return [];

    // Split the text based on the pattern [n]
    const sentences = text?.split(/\[\d+\]/).filter(sentence => sentence && sentence != '"');

    return sentences;
};

const goodDate = (date) => {
  // Vérifie si l'objet est une instance de Date
  if (date instanceof Date) {
    return date;
  }
  // Vérifie si l'objet a une méthode toDate (format Firebase Timestamp)
  else if (date && typeof date.toDate === 'function') {
    return date.toDate();
  }
  // Si l'objet n'est ni une Date ni un Timestamp, renvoie null ou lance une erreur
  else {
    throw new Error("Invalid date format");
  }
}

const numberSentences = (text) => {
  if (!text) return '';

  const clearedText = text
   
      .replace(/\[\d+\]/g, '');

  const protectedText = clearedText.replace(/\b(Dr|Mrs|Ms|Mr|Jr|Sr|Prof|Mx)\./g, '$1<dot>');

  // Diviser les phrases en utilisant la regex, en conservant les sauts de ligne
  let sentences = protectedText.match(sentenceEndRegex);

  // Traiter les phrases pour ajouter les numéros
  let result = '';
  let sentenceCounter = 1;
  let remainingText = clearedText;

  sentences.forEach((sentence) => {
      const trimmedSentence = sentence.trim();
      if (trimmedSentence.length > 0) {
          const startIndex = remainingText.indexOf(sentence);
          const prefix = remainingText.slice(0, startIndex);
          remainingText = remainingText.slice(startIndex + sentence.length);

          result += prefix + `[${sentenceCounter}] ${trimmedSentence} `;
          sentenceCounter++;
      } else {
          result += sentence;
      }
  });

  result += remainingText;

  return result.replace(/<dot>/g, '.').trim();
};

  
  function removeQuotes(text) {
    const result = text.replace(/"/g, ''); // Utilisation d'une expression régulière pour remplacer tous les guillemets doubles ("") par une chaîne vide
  
    return result;
  }

  function removeDuplicates(arr) {
    return [...new Set(arr)];
}
  export {
    gpt, 
    removeDuplicates,
    speak, 
    numberSentences,
    transformText,
    fetch50k,
    sentenceEndRegex,
    reduceInput,
    goodDate,
    splitNumeredSentence,
    getTextInParentheses,
    removeTextInParentheses,
    removeQuotes,clean,
    addBracket,
    textToSpeech, findWord,
    highlightedWords, 
    addBracketsToString, 
    makeBold, 
    getCons, 
    tts,
    extractTextWithTags,
    imageGenerator, 
    capitalizeFirstLetter,
    getTitle, 
    stopSound,textSize,textSizeXl,
    formatTime,
    getLemma,
    textLvl,
    soundValid,
    soundBad,
    uploadAudio,
    shuffleArray,
    replaceBracketsWithInput,
    replaceBracketsWithUnderLine
  }


