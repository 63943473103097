import { useState, useEffect } from "react";
import { db } from "../../config.js";
import { BrowserRouter as Router, Routes, Route, Link, useParams} from 'react-router-dom';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs, startAt, endAt, getCountFromServer } from "firebase/firestore";


import { ImageUpload } from "../components/imageUpload.js";
import {displayDate, displayDuration, getCustomDayOfWeek, getWeekNumberAndYear} from '../../utilities/helper/helper'
import { dataUWPAdmin, renderUWPAdmin } from "./ClassScreen.js";
import { capitalizeFirstLetter } from "../../utilities/helper/text.js";
import { Cadena, LockScreen } from "./perfectScreen.js";
import { daysBetween, formatDate, getUserWeekXp, handleStreak, setUserWeekXp } from "../components/learn/learn.js";


function letterToNumber(letter) {
  return letter.toUpperCase().charCodeAt(0) - 64;
}



// faire une ligue par user
// faire calcul xp dans user weeks: 23-24|34, ...

/// prendre en compte les fakes 



const getUserWorkspaces = async (user, set) => {
  console.log('getUserWorkspaces 🟢')
  const q = query(collection(db, 'user_workspace'),  where('user_id', "==", user?.id))
  const querySnapshot = await getDocs(q);
  const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))        
  set(userWorkspaces)
  console.log('getUserWorkspaces ✅', userWorkspaces.length)

}


const UserLine = ({user, i, ct, yesterdayJM, todayJM, me}) => {

  const [extended, setExtended] = useState()
  const [userWorkspaces, setUserWorkspaces] = useState()


  



  return <div>

    <div className={`text-white fredoka py-2 border-b  border-white/20 px-4 ${user.id == ct.user.id && "bg-indigo-400/20"} `}>
    <div onClick={() => {
      console.log('USEERRRRR', user)
      setExtended(e => !e)
      getUserWorkspaces(user, setUserWorkspaces)
      }} >
        <div className="flex gap-4 items-center">
          <div className={`${i > 19 && (me.lvl_lig > 1) && "text-red-300"} ${i < 10 && "text-green-300"} w-[20px]`}>{i + 1}</div>
          {user.photo ? 
            <img src={user.photo} className='h-[36px] w-[36px] rounded-full object-cover'/> 
          : <div className="h-[36px] w-[36px] rounded-full font-bold flex items-center justify-center bg-blue-500" style={{backgroundColor: user.color}}>{user?.name?.[0]?.toUpperCase()}</div> }
           <div className="flex grow justify-between">
            <div className={`font-semibold ${user.id == ct.user.id ? "text-amber-400" : ""}`}>
              {user?.name.split(' ')?.[0]} {user?.name.split(' ')?.[1]?.[0]} 
              {user.streak && (user.lds == yesterdayJM || user.lds == todayJM) ? <span className={`${user.lds != todayJM && "opacity-50"} text-amber-500 text-sm ml-2`}>{user.streak} 🔥</span> : ""}
              <div className="text-xs font-[500] -ml-2">  
                {(new Date()-user.last_connexion?.toDate())/1000 < 60*5 ? <div className="flex "><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden  text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-green-500 block h-2 w-2 rounded-full"></span >En ligne</div></div> : 
                (new Date()-user.last_connexion?.toDate())/1000 < 60*180 ? <div className="flex "><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden  text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-amber-500 block h-2 w-2 rounded-full"></span >En ligne récemment</div></div> : ""}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="text-amber-400"> {user?.week_table?.[`${todayWeek}`]?.xp || 0} XP</div>
              {user.id ? <div> 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${extended ? "rotate-[90deg]" : ""}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                </div> : <div className="w-6"></div>}
            </div>
            </div>
          </div>
        </div>
        {extended && <div className="p-2">
          <div>{user.id == me.id ? <div>
            <div className="flex ml-[28px]" ><Link to={"/me"} className="py-2 mt-2 block bg-purple-500 border-2 border-b-4  border-black/90 px-4 rounded-xl">Modifier mon profil</Link></div>
          </div> : userWorkspaces
          ?.filter(uwp => uwp.total_xp  > 5)
          .sort((a, b) => b.total_xp - a.total_xp)
          .map(uwp => {
            const wp = ct.workspaces.find(wp => wp.id == uwp.workspace_id)
            return <Link to={`/forest?&uwp=${uwp.id}`} className="rounded-xl text-sm mt-1 p-2 relative flex gap-2 items-center bg-black/10 border-black/70 border-2 border-b-4 ">
            <img className="h-5" src={wp.img}/>{capitalizeFirstLetter(wp.name)}
              <div className="absolute py-1 bg-black/50  rounded-xl px-2 items-center right-1 flex gap-1">
                <div>visiter</div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5  h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
              <div className="grow text-right text-amber-300 mr-[90px] text-sm">{uwp.total_xp} XP </div>
            </Link>
            
            })}</div>
            {ct.user.admin && <div className="px-2 rounded-full bg-white/10 mt-2" onClick={() => {
              ct.setUser(user)
            }}>Impersonnate</div>}
          </div>}
  </div>
 
  {i == 9 && (me.lig_lvl < 2) && <div  className="flex justify-center bg-green-500/20 items-center border-b border-white/20 ">
    <div className="text-green-500 flex justify-center py-1 items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
        </svg>
        Zone de promotion <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
        </svg>
    </div>
</div>}
  {i == 19 && (me.lig_lvl > 1) && <div className="flex justify-center items-center border-b bg-red-500/10   border-white/20 ">
  <div className="text-red-200 flex justify-center py-1 items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 rotate-[180deg]">
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
        </svg>
        Zone de relégation 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 rotate-[180deg]">
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
        </svg>
    </div>
    </div>}

  </div>
}


const today = new Date();
const todayWeek = getWeekNumberAndYear(today);
const todayDay = getCustomDayOfWeek(today);
console.log('todayDay', todayDay)
const currentLigue = 0

const sumFirstNElements = (arr, n) => arr.slice(0, n).reduce((sum, num) => sum + num, 0);


const bots = [
  {name: ["Stef"],      id: "QeDXwmcsJGfTqCOVCuAl", streak: 0, xp_weeks: [12, 4, 12, 20, 4, 6, 10]},
  {name: ["Tanguy"],    id: "Blng6c7W1Kn3uWUPxTiG", streak: 0, xp_weeks: [12, 20, 12, 20, 12, 20, 12]},
  {name: ["Robin"],     id: "FGtyo7h44yceJGRYlbET", streak: 4, xp_weeks: [60, 40, 56, 60, 43, 46, 50]},
  {name: ["Cindy"],     id: "sZ38WYY7ioLVjiO6jBJD", streak: 2, xp_weeks: [12, 4, 20, 12, 20, 12, 12]},
  {name: ["Jason"],     id: "4NHoaMeS5Fv61ZSb7wPp", streak: 0, xp_weeks: [12, 20, 12, 33, 16, 12, 12]},
  {name: ["darkS"],     id: "XgyNzrk7C7QgmZoxAYo0", streak: 0, xp_weeks: [12, 12, 33, 40, 16, 12, 12]},
  {name: ["Minimator"], id: "Yr66TH24zKQmixyKbYiN", streak: 0, xp_weeks: [20, 12, 4, 20, 12, 33, 12]},
  {name: ["Tim L"],       id: "BnmfyFH8NH3ybNemuAav", streak: 0, xp_weeks: [10, 20, 12, 33, 6, 33, 12]},
  {name: ["Julien"],    id: "CV1E5AUOPf5QGXhaNOGg", streak: 1, xp_weeks: [12, 12, 20, 40, 33, 16, 12]},
  {name: ["CHRISTIAN"], id: "zL23EW3c92Tk7igzqAeH", streak: 0, xp_weeks: [17, 12, 6, 33, 12, 6, 12]},
  {name: ["Jule K"],      id: "7yt1ATrLTSr1cgcjmjw9", streak: 0, xp_weeks: [16, 20, 16, 12, 12, 12, 12]},
  {name: ["JP"],        id: "jc2b0HhcJebhDSg6BVe7", streak: 2, xp_weeks: [18, 4, 6, 20, 40, 12, 12]},
  {name: ["Maelle"],    id: "0wbF7lh1ulmMij3enVC0", streak: 0, xp_weeks: [19, 20, 12, 12, 6, 12, 12]},
  {name: ["Tbs"],       id: "RqXFTO4qVVOihb9cqQy5", streak: 4, xp_weeks: [12, 12, 16, 4, 12, 16, 12]},
  {name: ["Didi"],      id: "3XiyW5VkaThsDccjODv6", streak: 0, xp_weeks: [14, 6, 20, 12, 33, 40, 12]},
  {name: ["Marie P"],     id: "Jz51jRmV5UkxEpW6ZQwz", streak: 4, xp_weeks: [20, 12, 33, 16, 40, 12, 12]},
  {name: ["Manon"],     id: "5cXL5eM0bMj6nIEXR6wc", streak: 0, xp_weeks: [34, 4, 16, 6, 40, 12, 12]},
  {name: ["Charle D"],    id: "apIPcWnFga5un0GYIL8C", streak: 2, xp_weeks: [40, 12, 20, 12, 4, 33, 12]},
  {name: ["Noé"],       id: "fsuigWzm9cW181mhZwSi", streak: 1, xp_weeks: [22, 16, 12, 20, 40, 12, 12]},
  {name: ["Louis"],     id: "SeR0Ey1PEasZACLizTHm", streak: 0, xp_weeks: [25, 4, 33, 12, 6, 40, 12]},
  {name: ["Justine"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 16, 20, 12, 12, 33, 12]},
  {name: ["Juju"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 16, 20, 12, 12, 33, 12]},
  {name: ["Justine"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [10, 20, 70, 10, 20, 70, 30]},
  {name: ["Mattéo L"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 30, 30, 30, 30, 30, 30]},
  {name: ["Lucien M"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [10, 50, 10, 50, 10, 50, 10]},
].map(bot => (
  {...bot,
    name: bot.name[currentLigue],
   
    week_table: {[`${todayWeek}`]: {xp: bot.xp_weeks.slice(0, todayDay + 1).reduce((sum, num) => sum + num, 0) }}
}))

console.log('todayWeek', todayWeek)
console.log('todayDay', todayDay)








const checkLeague = async (_user, setUser) => {
  console.log('checkLeague', _user)

  // on fait un call pour avoir notre dernière version de users
  const user = (await getDoc(doc(db, "users", _user.id))).data()
  const today = new Date();
  const todayWeek = getWeekNumberAndYear(today);
  const ligue_week = user?.lig_id?.split('_')?.[0]
  const new_user = {}


  if (ligue_week == todayWeek) return 
  console.log('changement de lig_id !!!')
  // si on est plus dans la bonne semaine ou qu'on as pas de ligue attribué

  if (user.lig_id) {
    console.log('first !!!')
    // si on fait déjà partie d'une ligue ou qu'on est le premier de notre ligue
    const usersCurrent = (await getDocs( query( collection(db, "users"), where('lig_id', "==", user.lig_id)))).docs.map(doc => ({...doc.data(), id: doc.id}))

    console.log('classement des joueurs !!', usersCurrent)

    const players = usersCurrent
    .concat(bots.slice(0, 30 - usersCurrent.length))
    .sort((a, b) => (b?.week_table?.[`${ligue_week}`]?.xp || 0) - (a?.week_table?.[`${ligue_week}`]?.xp || 0))
    console.log('classement des joueurs final!!', players)


    players.forEach((player, index) => {
      let niv = player.lig_lvl || 1
      if (index <= 10 )
      {
        console.log('augmentation de niveau de ligue pour', player.name)
        niv ++
        if (niv > 2) niv = 3
      }
      if (index >= 20) {
        niv --
        if (niv < 1) niv = 1
        console.log('decrémentation de niveau de ligue pour', player.name)
      }
      if (player.id == user.id) {
        console.log('update de mon niveau', niv)
        new_user.lig_lvl = niv
      } else {
        if (player.id) {
          updateDoc(doc(db, "users", player.id), {lig_id: null, lig_lvl: niv})
        }
      }

    })

  } else {
    new_user.lig_lvl = user.lig_lvl || 1
  }

  const q = query(
    collection(db, "users"),
    where('lig_lvl', "==", new_user.lig_lvl || 1),
    where('lig_w', "==", todayWeek)
  );

  const snapshot = await getCountFromServer(q);
  const nUser = snapshot.data().count
  const tranche = Math.floor((nUser || 0) / 20) || 0;

  new_user.lig_id = todayWeek + "_" + tranche + "_" + (new_user.lig_lvl || 1)
  new_user.lig_w = todayWeek

  
  console.log('new_user', new_user)
  setUser(u => ({...u, ...new_user}))
  updateDoc(doc(db, "users", _user.id), {lig_w: todayWeek, lig_id: new_user.lig_id, lig_lvl: new_user.lig_lvl})


}




const RenderUWP = ({uwp, index, ct}) => {
  const userWorkspace = ct.userWorkspace;
  const score = uwp.total_xp
  const tillNextStreak = uwp.lastStreakDate?.toDate()

  tillNextStreak?.setDate(tillNextStreak?.getDate() +2)
  const [expend, setExpend] = useState(false)

return <Link key={uwp.id} id={uwp.user_id} onClick={() => {uwp.others?.length > 1 && setExpend(!expend)}} to={uwp.others?.length > 1 ? null : `/forest?&uwp=${uwp.id}`} className={` ${uwp.id == ct.userWorkspace?.id ? "bg-[#49199e] border-amber-600" : "bg-[#49199e]  border-black"}  border-b-[5px] block px-2 rounded-xl p-2 mb-1 rounded relative border `}>
        <div className="flex items-center gap-4 justify-between" >
          <div onClick={() => console.log('uwp', uwp)} className="flex items-center text-left gap-2 ">
              <div onClick={() => userWorkspace.role == "admin"} className='w-[20px] opacity-50 text-right absolute -left-5 pr-2 text-slate-400 text-xs rounded-md w-[16px]'>{index + 1}</div> 
              
              {uwp.user_photo ? <img src={uwp.user_photo} className='w-7 h-7 border-2 text-slate-400 bg-slate-600 rounded-full object-cover'/>  : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-400 p-1 bg-[#07032538] rounded-full ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />


              </svg>}

              <div className={`text-left flex items-center text-yellow-200 ${uwp.user_name?.length > 16 ? "text-xs" : "text-sm"}  grow  lg:w-[200px] font-bold py-1 rounded-md capitalize  ${userWorkspace?.role == "admin" ? "hover:bg-slate-600" : "" }`} style={{textWrap: "nowrap"}}><span className="!max-w-[130px]  inline-block overflow-hidden text-ellipsis">{uwp?.user_name}</span>  {uwp?.streak && tillNextStreak > new Date(Date.now()) && <span className="ml-2 text-amber-500 -mr-2">{uwp.streak} 🔥</span>}
              </div> 


          </div>


          <div className='flex gap-2 items-center '>
          
          <div className='flex gap-1 '>

          <div className='bg-[#07032538] w-16 font-semibold text-right justify-end text-yellow-600 px-2 py-1 rounded-md flex gap-2 items-center'>{score || 0} 
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              
              <path d="M3.98361 10.3225L4.19624 9.85061L3.88307 9.43468L1.07138 5.70065C1.00192 5.59016 0.958859 5.38197 1.05927 5.06589C1.1729 4.94554 1.35612 4.85397 1.5136 4.83263L1.54366 4.83849L1.59248 4.84285L6.21197 5.25612L6.74437 5.30373L7.05239 4.87985L9.5628 1.42528C9.65585 1.36157 9.72678 1.28997 9.77867 1.22535C9.79318 1.20725 9.80659 1.18928 9.81891 1.17161C10.0003 1.01365 10.1894 0.985368 10.2947 1.00585C10.358 1.01817 10.3845 1.04229 10.3916 1.05058C10.3951 1.05464 10.4015 1.06316 10.4076 1.08242C10.414 1.10234 10.3958 0.978808 10.3916 1.05058L10.6235 2.04093L11.5147 5.94228L11.6304 6.44862L12.1405 6.60381L16.5669 7.95061L16.6137 7.96484L16.6618 7.97419L16.6668 7.97518C16.8387 8.05683 16.9488 8.20489 16.9925 8.4348C17.0252 8.60662 16.948 8.78139 16.8109 8.86995L16.7167 8.93079L14.5822 10.1494L14.5601 10.1621L14.5387 10.1759C13.8164 10.6424 13.1914 11.0433 12.8234 11.2138L12.288 11.4618L12.2975 12.0372L12.3713 16.4934C12.3554 16.7948 12.2067 16.9174 12.1185 16.9478L12.0736 16.9634L12.0306 16.9833C11.9899 17.0022 11.8455 17.0202 11.6536 16.9465C11.6035 16.8893 11.5513 16.843 11.5029 16.8064C11.47 16.7815 11.4374 16.7601 11.4063 16.7416L7.98976 13.8976L7.59623 13.57L7.10286 13.725L2.71326 15.1045L2.56244 15.1519C2.53637 15.1473 2.50706 15.1416 2.46772 15.134C2.4218 15.125 2.38454 15.1097 2.36 15.0953C2.34876 15.0886 2.34259 15.0836 2.34 15.0814L2.28036 14.9945L2.1962 14.9202C2.12699 14.859 2.03848 14.6904 2.14366 14.4063L3.98361 10.3225Z" fill="#FFCF4C"/>
              <path d="M10.3916 1.05058C10.3845 1.04229 10.358 1.01817 10.2947 1.00585C10.1894 0.985368 10.0003 1.01365 9.81891 1.17161C9.80659 1.18928 9.79318 1.20725 9.77867 1.22535C9.72678 1.28997 9.65585 1.36157 9.5628 1.42528L7.05239 4.87985L6.74437 5.30373L6.21197 5.25612L1.59248 4.84285L1.54366 4.83849L1.5136 4.83263C1.35612 4.85397 1.1729 4.94554 1.05927 5.06589C0.958859 5.38197 1.00192 5.59016 1.07138 5.70065L3.88307 9.43468L4.19624 9.85061L3.98361 10.3225L2.14366 14.4063C2.03848 14.6904 2.12699 14.859 2.1962 14.9202L2.28036 14.9945L2.34 15.0814C2.34259 15.0836 2.34876 15.0886 2.36 15.0953C2.38454 15.1097 2.4218 15.125 2.46772 15.134C2.50706 15.1416 2.53637 15.1473 2.56244 15.1519L2.71326 15.1045L7.10286 13.725L7.59623 13.57L7.98976 13.8976L11.4063 16.7416C11.4374 16.7601 11.47 16.7815 11.5029 16.8064C11.5513 16.843 11.6035 16.8893 11.6536 16.9465C11.8455 17.0202 11.9899 17.0022 12.0306 16.9833L12.0736 16.9634L12.1185 16.9478C12.2067 16.9174 12.3554 16.7948 12.3713 16.4934L12.2975 12.0372L12.288 11.4618L12.8234 11.2138C13.1914 11.0433 13.8164 10.6424 14.5387 10.1759L14.5601 10.1621L14.5822 10.1494L16.7167 8.93079L16.8109 8.86995C16.948 8.78139 17.0252 8.60662 16.9925 8.4348C16.9488 8.20489 16.8387 8.05683 16.6668 7.97518L16.6618 7.97419L16.6137 7.96484L16.5669 7.95061L12.1405 6.60381L11.6304 6.44862L11.5147 5.94228L10.6235 2.04093L10.3916 1.05058ZM10.3916 1.05058C10.3951 1.05464 10.4015 1.06316 10.4076 1.08242C10.414 1.10234 10.3958 0.978808 10.3916 1.05058Z" stroke="#FFBB00" stroke-width="2"/>
            </svg>
          </div>
          <div className="text-slate-400 py-1 bg-slate-800/20 rounded-md items-center flex gap-1 text-sm p">
              
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${uwp.others?.length > 1 ? "opacity-50" : ""} w-6 h-6`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>

            </div>

          </div>
          
          </div>
          </div>
          <div>
         {(new Date()-uwp.updatedDate?.toDate())/1000 < 60*5 ? <div className="flex pl-[30px] -mt-2"><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden text-sm text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-green-500 block h-2 w-2 rounded-full"></span >En ligne</div></div> : 
         (new Date()-uwp.updatedDate?.toDate())/1000 < 60*180 ? <div className="flex pl-[26px] -mt-2"><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden text-sm text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-amber-500 block h-2 w-2 rounded-full"></span >En ligne récemment</div></div> : ""}

          </div>
          {expend && <div className="pl-6">{uwp.others?.map(uwp => {

          const wp = ct.workspaces.find(wp => wp.id == uwp.workspace_id)
          return <Link to={`/forest?&uwp=${uwp.id}`} className="rounded-xl mt-1 p-1 relative flex gap-2 items-center bg-white/30">
            <img className="h-5" src={wp.img}/>{capitalizeFirstLetter(wp.name)}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 absolute right-2 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </Link>
            })}


            {ct.user.admin && <div className="px-2 rounded-full bg-white/10 mt-2" onClick={async() => {
            const user = (await getDoc(doc(db, "users", uwp.user_id))).data()
              ct.setUser(user)
            }}>Impersonnate</div>}
            </div>}



        </Link>
}

const getPlayer = async (lig_id, set) => {
  console.log('get player from 🟢🟢🟢🟢🟢')
  const usersCurrent = (await getDocs( query( collection(db, "users"), where('lig_id', "==", lig_id)))).docs.map(doc => ({...doc.data(), id: doc.id}))
  set(usersCurrent)
  console.log('data get player ✅', usersCurrent)
}




const LeaderScreen = (props) => {
  const ct = props.context
  const [userWorkspaces, setUserWorkspaces] = useState()
  const [selectedClass, setSelectedClass] = useState()
  const today = new Date();
  
  const todayWeek = getWeekNumberAndYear(today)
 
  // const dates = [];

  // for (let i = 1; i <= 10; i++) {
  //   const futureDate = new Date();
  //   futureDate.setDate(today.getDate() + i);
  //   dates.push(futureDate);
  // }


  // dates.forEach(date => {
  //   console.log('--' +  formatDate(date) + " : ", getWeekNumberAndYear(date)) 
  // })


  const [reelPlayers, setReelPlayers] = useState([])

  

  useEffect(() => {
    ct.user.lig_id && getPlayer(ct.user.lig_id, setReelPlayers)
  }, [ct.user.lig_id]) 

  useEffect(() => {

    if (ct.user?.visiting_uwp) {
      updateDoc(doc(db, "users", ct.user.id), {visiting_uwp: null, visit_last: new Date(), x: 0, y: 0})
      ct.setUser(u => ({...u, visiting_uwp: null}))
  
    }
  }, [ct.user?.id])
  

  useEffect(() => {
    checkLeague(ct.user, ct.setUser)
  }, [ct.user?.id]) 

// en fonction de la ligue en change le nom et on augmente le facteur de week_table 
const [users, setUsers] = useState([])  
const todayJM = formatDate(today)

// const reelplayers = (users || []).concat(ct.user || []);
const colors = ["#577CFF", "#8357FF", "#D357FF", "#FF57A8", "#57D7FF"]
const updatedBots = bots.map(b => ({...b, lds: todayJM}))
const players = (reelPlayers||[])
  .concat(updatedBots?.slice(0, 30 - (reelPlayers?.length || 0)))
  .sort((a, b) => (b?.week_table?.[`${todayWeek}`]?.xp || 0) - (a?.week_table?.[`${todayWeek}`]?.xp ||0))
  .map(e => ({...e, color: colors[(letterToNumber(e.name?.[0] || "a")%colors?.length||0)]}))

console.log('players', players)

  const getAllUserWorkspaces = async (wp_id, classe_id) => {
    const cutoffDate = new Date(Date.now() - (1000 * 60 * 60) * 60 * 24);
    console.log('getAllUserWorkspaces 🟢', wp_id)
    const q = !classe_id ? query(collection(db, 'user_workspace'), where("updatedDate", ">",cutoffDate))
    : query(
      collection(db, 'user_workspace'), 
      // where('workspace_id', "==", wp_id),
      where('total_xp', ">", 0),
      where('classes', 'array-contains', classe_id) // Vérifie si classe_id est dans le tableau classes
    );
    const querySnapshot = await getDocs(q);
    const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).map(uwp => !uwp.total_xp ? {...uwp, total_xp: 0} : uwp).filter(uwp => uwp.user_name)
    const uniqueUserWorkspaces = userWorkspaces
    .filter(uwp => uwp.user_name !== "test")
    .filter(uwp => uwp.total_xp  > 5)
    .sort((a, b) => b.total_xp - a.total_xp)
    .reduce((acc, uwp) => {
      const existingUWP = acc.find(item => item.user_id === uwp.user_id);
      if (!existingUWP) {
        acc.push({...uwp, user_id: uwp.user_id, others: [uwp] });
      } else {
        existingUWP.others.push(uwp);
      }
      return acc;
    }, []);
    console.log('getAllUserWorkspaces', uniqueUserWorkspaces)
    setUserWorkspaces(uniqueUserWorkspaces)
} 


  useEffect(() => {
    console.log("LeaderScreen loaded")
    console.log('CHANGEMENT USER')
    console.log('CT', ct)
    ct.setActiveScreen("leaderboard")
    ct.setShowBottomBar(true)
    // if (!ct.userWorkspace?.class) {
     
    //   const newUserWorkspace = {...ct.userWorkspace, class: ct.user?.class}
    //   ct.setUserWorkspaces(newUserWorkspace)
    //   console.log('newUserWorkspace', newUserWorkspace)
    //   //ct.fire.updateDb('user_workspace', ct.userWorkspace.id, {class: user.class})
    // }
    if (ct.workspace?.id) {
      ct.userWorkspace?.classes ? getAllUserWorkspaces(ct.workspace?.id, ct.userWorkspace?.classes[0]) : getAllUserWorkspaces(ct.workspace?.id)
      ct.userWorkspace?.classes ? setSelectedClass(ct.userWorkspace?.classes[0]) : setSelectedClass()

    }
    
   
}, [ct.user?.last_workspace_id, ct.userWorkspace?.id])


  const currentClass = ct.classes?.find(c => c.id == selectedClass)
  const lockerScreen = props.lockerScreen
  const blocked = !lockerScreen.leaderboard.access || !lockerScreen.leaderboard.unlocked


  const [screen, setScreen] = useState("league")

  const choiceScreen = [
    {id: "league", name: "Ligue"}, 
    {id: "top", name: "Top"},
    {id: "class", name: "Ma classe"},
    {id: "friends", name: "Mes amis"},
  ]

  const leagues = [
    { name: "Ligue de Bronze", img: "/images/UX/lig_1.svg"},
    {name: "Ligue d'argent", img: "/images/UX/lig_2.svg"},
    {name: "Ligue d'Or", img: "/images/UX/lig_3.svg"}
  ]




  


  const leagueUser = ct.user?.lig_id?.split('_')[2] - 1 

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const yesterdayJM = formatDate(yesterday)


  return (
    blocked ? <div className="bg-gradient-to-b from-[#5a3591] text-white to-[#36206c] h-screen p-4 flex items-center h-full w-full">
        
    <div className="-mt-[100px] w-full">
    
      <LockScreen title={"Classement"} id={"leaderboard"} goal={lockerScreen.leaderboard.goal} current={lockerScreen.tt} ct={ct} /> 
    </div>
   
  
  </div> : 
    <div className="bg-[#2a0068]  fredoka min-h-screen h-screen overflow-y-scroll">
      {ct.user.admin && <div className="flex border-b-2 z-[10] fixed h-[50px] bg-yellow-200 border-yellow-500  left-0 right-0 top-0 mb-4 gap-1 py-2 px-2">
        {choiceScreen.map(e => <div onClick={() => setScreen(e.id)} className={` text-center px-2 py-1 ${e.id == screen ? "text-slate-800" : "text-slate-800/20"} rounded-md`}>{e.name}</div>)}
      </div>}


    {screen == "league" && <div className="pb-[100px]"> 
    <div className=' py-4  pt-[30px] overflow-x-hidden bg-yellow-200  '>

          <div className="py-2  gap-2 flex items-center mx-auto justify-center">          
            {leagues.map(e => <img src={e.img} className={` ${e.name == leagues[leagueUser]?.name ? "h-[80px]" : "h-[60px]" }`}/>)}
            
          

          </div>
          <h1 onClick={async(e) => {
            console.log('e', e)
            const container = e.currentTarget;

            // Obtenir les coordonnées du clic par rapport à l'élément conteneur
            const rect = container.getBoundingClientRect();
            const offsetX = e.clientX - rect.right;
            const offsetY = e.clientY - rect.top;

            console.log('rect left', offsetX)
            console.log('rect top', offsetY)
       
          }} className='text-2xl md:text-5xl text-center pl-4 lg:pl-20'>{leagues[leagueUser]?.name}</h1>
          <div onClick={() => checkLeague(ct.user, ct.setUser)} className=" mt-4 text-amber-600 font-semibold text-center ">{7 - (todayDay)} jour{todayDay != 7 && "s"} restant{todayDay != 7 && "s"}</div>
        </div>


      {players?.map((u,i) =>   <UserLine i={i} ct={ct} todayJM={todayJM} me={ct.user} yesterdayJM={yesterdayJM} user={u} />
        )}
     



      </div>}



    {screen == "top" && <div> 

      <div>
        <div className='h-[140px] overflow-x-hidden bg-yellow-200 '>
        <img src="/images/deco/motivation.png" className='h-[100px] mx-auto'/>
          <h1 onClick={async() => {

         
            
            userWorkspaces.forEach(u => {
              console.log(u)
            }) 
          }} className='text-3xl -mt-4 md:text-5xl text-center pl-4 lg:pl-20'>Classement</h1>
        
        </div>

        <div className=' mx-auto text-white max-w-screen-md py-20 pl-8 px-6'>


        {ct.userWorkspace?.classes && ct.userWorkspace?.classes.length > 1 ? <div className="flex rounded-xl gap-2 -mt-16">
      <select 
        value={selectedClass} 
        onChange={(e) => {
          const classID = e.target.value;
          getAllUserWorkspaces(ct.workspace?.id, classID);
          setSelectedClass(classID);
        }}
        className="min-w-max rounded py-1 px-2 text-center bg-purple-500 text-white"
      >
        {ct.userWorkspace?.classes && ct.userWorkspace?.classes?.map(classID => (
          <option value={classID} key={classID} className="py-2">
            {ct.classes?.find(c => c.id == classID)?.name}
          </option>
        ))}
        <option value="" onClick={() => {getAllUserWorkspaces(ct.workspace?.id); setSelectedClass(null)}} className="py-2">Top France</option>
      </select>
        </div> : <div className="block block-scroll overflow-auto  -mt-16">
        <div className="flex  bg-black/10 rounded-xl gap-2 ">
        {ct.userWorkspace?.classes && ct.userWorkspace?.classes?.map(classID => <div onClick={() => {getAllUserWorkspaces(ct.workspace?.id, classID); setSelectedClass(classID)}} className={`${classID == selectedClass ? "bg-white/30" : "bg-white/10 opacity-50"} min-w-max	 rounded py-1 px-2 text-center `}>{ct.classes?.find(c => c.id == classID)?.name}</div>)}
        <div onClick={() => {getAllUserWorkspaces(ct.workspace?.id); setSelectedClass(null)}} className={`${!selectedClass ? "bg-white/30" : "bg-white/10 opacity-50"} min-w-max	 rounded py-1 px-2 text-center`}>Top France</div>
      </div></div>}

      <div className="mt-8">
        {currentClass?.showNote ? <Link to={"/classScreen"} className="text-right block mb-2 text-white/50 rounded px-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Voir les notes ma classe →</Link> : ""}
      </div>


      <div className="text-white"> </div>
        {userWorkspaces?.map((uwp, index) => (uwp.user_id == ct.user?.id && currentClass?.showNote ) ?renderUWPAdmin(dataUWPAdmin(uwp), index, null, true): <RenderUWP uwp={uwp} index={index} ct={ct} />)}

        

        </div>
          </div>
          </div>}
      </div>
  );
};

export {LeaderScreen};